@import url('//fonts.googleapis.com/css?family=Roboto:300,400,500,700');

body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
  box-sizing: border-box;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover, a:focus {
  color: #23527c;
  text-decoration: underline;
}

a:active, a:hover {
  outline: 0;
}
