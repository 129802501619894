.query-loading {
  padding: 30px;
  text-align: center;
}

.query-error {
  padding: 30px;
  text-align: center;
}

.query-error .error-content {
  max-width: 350px;
  display: inline-block;
}

.query-error .error-content span {
  display: block;
}

.query-error .error-icon {
  display: block;
}

.query-error .error-icon svg {
  color: rgba(255, 0, 0, 0.5);
  width: 40px;
  height: 40px;
}

.query-error .error-text {
  font-weight: 500;
  font-size: 18px;
  margin: 10px 0;
}

.query-error .description-text {
  color: #666;
  font-size: 14px;
}
