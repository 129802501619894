@import url(//fonts.googleapis.com/css?family=Roboto:300,400,500,700);
.page-error {
  text-align: center;
}

.page-error .inner {
  display: inline-block;
  text-align: left;
  margin-top: 50px;
}

.app-bar {
  background: #607d8b;
  height: 64px;
  line-height: 64px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
}

.app-bar .app-bar-icon {
  width: 30px;
  height: 30px;
}

.navigation-and-title {
  display: flex;
  min-width: 300px;
}

.app-bar .back-link {
  color: #fff;
  margin-right: 15px;
}

.app-bar .heading {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.app-bar .navigation * {
  color: #fff;
  display: inline-block;
  padding: 0 10px;
  text-decoration: none;
}

.app-bar .navigation button {
  line-height: 64px;
  background: none;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  outline: none;
}

.app-bar .navigation button:hover {
  background: rgba(255, 255, 255, 0.1)
}

.app-bar a svg {
  vertical-align: middle;
}

.container {
  max-width: 1100px;
  padding: 0 15px 100px;
}

.container.center {
  margin: 0 auto;
}

.heading {
  font-weight: 400;
}


h1, h2 { font-size: 24px;  }

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 14px 12px;
  text-align: left;
}

.table th {
  font-weight: 700;
  vertical-align: bottom;
  border-bottom: 2px solid #e9ecef;
}

.table td {
  vertical-align: middle;
  border-bottom: 1px solid #e9ecef;
}

.table .align-right { text-align: right;  }

/*
  Default button styles
*/
.btn {
  background: #fff;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  letter-spacing: 0;
  line-height: 36px;
  outline: 0;
  padding: 0 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.btn:hover {
  background: #ebebeb;
  color: inherit;
  text-decoration: none;
}

.btn:active {
  background: #d7d7d7;
  text-decoration: none;
}

.btn[disabled], .btn.disabled {
  opacity: 0.65;
  cursor: not-allowed;
  text-decoration: none;
  color: #000;
  color: initial;
}

.btn.disabled:active {
  background: #ebebeb;
}

/*
  Overrides based on class
*/
.btn.primary { background: #607d8b; color: #fff; }
.btn.primary:hover { background: #a0b1b9; }
.btn.primary:active { background: #98aab2; }
.btn.primary.disabled:active { background: #a0b1b9; }

.btn.next { background: #60a486; color: #fff; }
.btn.next:hover { background: #58977b; }
.btn.next:active { background: #4f8b70; }
.btn.next.disabled:active { background: #58977b; }

.btn.cancel { background: #df4e5c; color: #fff; }
.btn.cancel:hover { background: #dd3f4e; }
.btn.cancel:active { background: #c82333; }
.btn.cancel.disabled:active { background: #dd3f4e; }

.description-list {
  display: flex;
  margin: 0 0 15px;
}

.description-list.inline {
  flex-flow: row wrap;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 10px 0;
}

.description-list-term {
  display: flex;
  align-items: baseline;
  margin-right: 20px;
}

.description-list-term  dt {
  font-weight: 500;
  margin: 0 4px 0 0;
}

.description-list-term  dd {
  margin: 0;
}

textarea {
  background: #fff;
  border: 1px solid #dedede;
  font-size: 16px;
  padding: 5px;
  width: 100%;
}

textarea:disabled {
  background-color: #f1f1f1;
}

/* Overriding existing properties requires use of !important */

.ril__outer {
  background: rgba(0, 0, 0, 0.75) !important;
}

.ril__caption, .ril__toolbar {
  background: rgba(0, 0, 0, 1) !important;
}

.ril__captionContent {
  display: flex;
  flex: 1 1;
  padding: 10px 20px 20px !important;
}

.ril__navButtons {
  outline: none;
}

.toggle {
  display: flex;
  align-items: center;
}

.toggle span {
  padding-right: 10px;
  white-space: nowrap;
}

.top-actions-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Make sure it gets aligned to the right if there's only one child */
.top-actions-bar :only-child {
  margin-left: auto;
}

.asset-entry-header {
  display: flex;
}

.asset-entry-header > * {
  flex-basis: 150px;
  margin: 0 0 6px 20px;
}

.asset-entry-header > *:first-child {
  margin-left: 0;
}

.removePhoto {
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  padding: 0px 4px;
  font-size: 18px;
  z-index: 10;
  line-height: 16px;
  border: 0;
}

.removePhoto:hover {
  text-decoration: none;
  color: #fff;
}

.removePhoto:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 32px 32px 0;
  border-color: transparent #000 transparent transparent;
  right: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}

.asset-photo-wrapper {
  position: relative;
  display: inline-block;
}

.asset-list-entry .asset-photo .asset-photo-wrapper img {
  max-height: 100px;
}

.multiple-photos-uploader {
  margin-bottom: 20px;
  border-bottom: 1px solid #dadada;
}

.multiple-photos-uploader .label {
  display: block;
}

.multiple-photos-uploader .description {
  display: block;
  font-size: 12px;
}

.multiple-photos-uploader input {
  margin: 10px 0 20px;
}

.task-actions {
  display: flex;
  justify-content: flex-end;
  padding: 15px 0 0;
}

.task-actions .btn {
  margin-left: 15px;
}

ol.timeline-picker {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-bottom: 20px;
  overflow: hidden;
}

ol.timeline-picker li {
  margin: 0 20px;
  text-align: center;
  position: relative;
}

ol.timeline-picker li::before {
  content: '';
  height: 1px;
  background: #979797;
  width: calc(100% + 50px);
  position: absolute;
  top: 19px;
  z-index: -1;
}

ol.timeline-picker li:last-child::before {
  content: none;
}

ol.timeline-picker li button {
  cursor: pointer;
  text-transform: capitalize;
  text-decoration: none;
  color: inherit;
  font-weight: 700;
  background: none;
  border: none;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  padding: 0;
  float: left;
  outline: none;
}

ol.timeline-picker li button::before {
  cursor: auto;
  content: '';
  background: #D7D7D7;
  width: 18px;
  height: 18px;
  border-radius: 18px;
  display: block;
  margin: 10px auto;
}

ol.timeline-picker li button.active::before {
  background: #4A90E2;
}

ol.timeline-picker button span {
  display: block;
  font-weight: 400;
  color: #777;
  font-size: 90%;
}

.asset-list-entry {
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.asset-list-entry .asset-photo {
  width: 150px;
}

.asset-list-entry .asset-photo img {
  max-height: 150px;
  max-width: 150px;
}

.asset-list-entry .asset-info {
  margin-left: 20px;
  flex-grow: 1;
}

.asset-list-entry .asset-headers {
  display: flex;
  margin-bottom: 10px;
  font-size: 14px;
}

.asset-header {
  margin-right: 10px;
}

.asset-title {
  font-weight: 500;
}

.asset-image-name-wrapper {
  margin-left: 10px;
}

.asset-image-name {
  text-transform: capitalize;
  padding-left: 5px;
}

.gallery-caption-actions {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
}

.gallery-caption-actions textarea {
  margin-right: 15px;
}

.radio-group label {
  margin-right: 20px
}

.radio-group input {
  margin-right: 5px
}

.enhanced-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 0;
}

.enhanced-list > div {
  padding: 10px;
  width: 33%;
}

.enhanced-list img {
  width: 100%;
}

.enhanced-list img.loading {
  -webkit-filter: blur(20px);
          filter: blur(20px);
  opacity: 0.6;
}

.enhanced-list a {
  display: block;
  overflow: hidden;
}

.enhance-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1;
}

.enhanced-list-entry .actions {
  display: flex;
  justify-content: space-between;
}

.authenticate {
  background: #fff;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 120px auto;
  min-height: 160px;
  padding: 32px;
  width: 360px;
}

.query-loading {
  padding: 30px;
  text-align: center;
}

.query-error {
  padding: 30px;
  text-align: center;
}

.query-error .error-content {
  max-width: 350px;
  display: inline-block;
}

.query-error .error-content span {
  display: block;
}

.query-error .error-icon {
  display: block;
}

.query-error .error-icon svg {
  color: rgba(255, 0, 0, 0.5);
  width: 40px;
  height: 40px;
}

.query-error .error-text {
  font-weight: 500;
  font-size: 18px;
  margin: 10px 0;
}

.query-error .description-text {
  color: #666;
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

*, *:before, *:after {
  box-sizing: border-box;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover, a:focus {
  color: #23527c;
  text-decoration: underline;
}

a:active, a:hover {
  outline: 0;
}

